/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/prop-types */
import { PureComponent } from 'react';

/** @namespace Efex/MorhaneSeo/Component/TwitterCard/Component */
export class TwitterCardComponent extends PureComponent {
    getGeneralFields() {
        const {
            entity: {
                meta_description = '',
            } = {},
            customVariables: {
                twitter_username = '',
            } = {},
        } = this.props;

        return (
            <>
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:site" content={ twitter_username } />
              <meta name="twitter:creator" content={ twitter_username } />
              <meta name="twitter:description" content={ meta_description } />
            </>
        );
    }

    renderProduct() {
        const {
            entity: {
                price_range: {
                    maximum_price: {
                        default_final_price: {
                            value = 0,
                        } = {},
                    } = {},
                } = {},
                stock_status = '',
                image: {
                    url: proudctImage,
                } = {},
                name = '',
                meta_title = '',
            } = {},
        } = this.props;

        const stockStatusMap = {
            IN_STOCK: 'in stock',
            OUT_OF_STOCK: 'out of stock',
        };

        const availability = stockStatusMap[stock_status] || '';
        const choosedTitle = meta_title === '' || meta_title === null ? name : meta_title;

        return (
            <>
                { this.getGeneralFields() }
                <meta name="twitter:title" content={ choosedTitle } />
                <meta name="twitter:image" content={ proudctImage } />
                <meta name="twitter:label1" value={ __('Price') } />
                <meta name="twitter:data1" value={ value.toFixed(2) } />
                <meta name="twitter:label2" value={ __('Availability') } />
                <meta name="twitter:data2" value={ __(availability) } />
            </>
        );
    }

    renderCategory() {
        const {
            entity: {
                image = '',
                meta_title = '',
                name = '',
            } = {},
            customVariables: {
                twitter_image = '',
            } = {},
        } = this.props;

        const choosedImage = image === '' || image === null ? twitter_image : image;
        const choosedTitle = meta_title === '' || meta_title === null ? name : meta_title;

        return (
            <>
                { this.getGeneralFields() }
                <meta name="twitter:title" content={ choosedTitle } />
                <meta name="twitter:image" content={ choosedImage } />
            </>
        );
    }

    renderCms() {
        const {
            entity: {
                meta_title = '',
                title = '',
            } = {},
            customVariables: {
                twitter_image = '',
            } = {},
        } = this.props;

        const choosedTitle = meta_title === '' || meta_title === null ? title : meta_title;

        return (
            <>
                { this.getGeneralFields() }
                <meta name="twitter:title" content={ choosedTitle } />
                <meta name="twitter:image" content={ twitter_image } />
            </>
        );
    }

    renderEntity() {
        const { type = '' } = this.props;
        switch (type) {
        case 'product':
            return this.renderProduct();
        case 'category':
            return this.renderCategory();
        case 'cms_index_index':
            return this.renderCms();
        case 'cms':
            return this.renderCms();
        default:
            return this.getGeneralFields();
        }
    }

    render() {
        return this.renderEntity();
    }
}

export default TwitterCardComponent;
