/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request/Query';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import HreflangUrlsQuery from '../../query/HreflangUrls.query';
import MicrodataQuery from '../../query/Microdata.query';
import OpengraphComponent from './Opengraph.component';

/** @namespace Efex/MorhaneSeo/Component/Opengraph/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    configurations: state.ConfigReducer,
    meta: state.MetaReducer,
    seoConfigurations: state.SeoConfigurationReducer.seoConfigurations,
});

/** @namespace Efex/MorhaneSeo/Component/Opengraph/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Efex/MorhaneSeo/Component/Opengraph/Container */
export class OpengraphContainer extends PureComponent {
    static defaultProps = {
        configurations: {},
    };

    __construct() {
        this.state = {
            organizationMicrodata: [],
            hreflangLocales: [],
        };
    }

    componentDidMount() {
        const {
            configurations: {
                code,
            } = {},
        } = this.props;

        this.fetchCustomVariables(code);
    }

    componentDidUpdate() {
        const { hreflangLocales } = this.state;

        if (hreflangLocales.length === 0) {
            this.fetchHreflangLocales();
        }
    }

    fetchCustomVariables(code) {
        fetchQuery(MicrodataQuery.getOrganizationCustomVariables(code))
            .then(
                /** @namespace Efex/MorhaneSeo/Component/Opengraph/Container/OpengraphContainer/fetchCustomVariables/then/catch/fetchQuery/then */
                (response) => {
                    const { organizationMicrodata } = this.state;
                    const result = response?.customVariableConfig;
                    this.setState(
                        {
                            organizationMicrodata: {
                                ...organizationMicrodata,
                                ...result,
                            },
                        }
                    );
                }
            ).catch(
                /** @namespace Efex/MorhaneSeo/Component/Opengraph/Container/OpengraphContainer/fetchCustomVariables/then/catch */
                (e) => console.log(e)
            );
    }

    fetchHreflangLocales() {
        const { entity = {}, type = '' } = this.props;
        const { id: productID = '' } = entity;

        // Check if productID is not empty
        if (productID !== '' || type === 'cms_index_index') {
            fetchQuery(HreflangUrlsQuery.getHreflangUrls(type === 'cms_index_index' ? 1 : productID, type))
                .then(
                    /** @namespace Efex/MorhaneSeo/Component/Opengraph/Container/OpengraphContainer/fetchHreflangLocales/then/catch/fetchQuery/then */
                    (response) => {
                    // Extract locales from the response
                        // Update the state with the fetched locales
                        this.setState({ hreflangLocales: response?.hreflangUrls || [] });
                    }
                )
                .catch(
                    /** @namespace Efex/MorhaneSeo/Component/Opengraph/Container/OpengraphContainer/fetchHreflangLocales/then/catch */
                    (error) => {
                        console.error('Error fetching hreflang locales:', error);
                    }
                );
        }
    }

    containerProps() {
        const {
            configurations,
            meta,
            type,
            entity,
            seoConfigurations,
        } = this.props;

        const { organizationMicrodata, hreflangLocales } = this.state;

        return {
            configurations,
            meta,
            type,
            entity,
            seoConfigurations,
            organizationMicrodata,
            hreflangLocales,
        };
    }

    render() {
        return (
            <OpengraphComponent
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpengraphContainer);
