/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request/Query';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import MetaRobotsQuery from '../../query/MetaRobots.query';
import MetaRobotsComponent from './MetaRobots.component';

/** @namespace Efex/MorhaneSeo/Component/MetaRobots/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    configurations: state.ConfigReducer,
    meta: state.MetaReducer,
    seoConfigurations: state.SeoConfigurationReducer.seoConfigurations,
});

/** @namespace Efex/MorhaneSeo/Component/MetaRobots/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Efex/MorhaneSeo/Component/MetaRobots/Container */
export class MetaRobotsContainer extends PureComponent {
    static defaultProps = {
        configurations: {},
    };

    __construct() {
        this.state = {
            metaRobots: {},
        };
    }

    componentDidMount() {
        this.fetchEntityMetaRobots();
    }

    componentDidUpdate() {
        const { metaRobots } = this.state;

        if (!metaRobots?.robots) {
            this.fetchEntityMetaRobots();
        }
    }

    fetchEntityMetaRobots() {
        const { entity = {}, type = '' } = this.props;
        const { id: productID = '' } = entity;

        // Check if productID is not empty
        if (productID !== '' || type === 'cms_index') {
            fetchQuery(MetaRobotsQuery.getMetaRobots(type === 'cms_index' ? 1 : productID, type))
                .then(
                    /** @namespace Efex/MorhaneSeo/Component/MetaRobots/Container/MetaRobotsContainer/fetchEntityMetaRobots/then/catch/fetchQuery/then */
                    (response) => {
                        this.setState({ metaRobots: response?.metaRobots || {} });
                    }
                )
                .catch(
                    /** @namespace Efex/MorhaneSeo/Component/MetaRobots/Container/MetaRobotsContainer/fetchEntityMetaRobots/then/catch */
                    (error) => {
                        console.error('Error fetching hreflang locales:', error);
                    }
                );
        }
    }

    containerProps() {
        const {
            configurations,
            meta,
            type,
            entity,
            seoConfigurations,
        } = this.props;

        const { metaRobots } = this.state;

        return {
            configurations,
            meta,
            type,
            entity,
            seoConfigurations,
            metaRobots,
        };
    }

    render() {
        return (
            <MetaRobotsComponent
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaRobotsContainer);
