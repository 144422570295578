/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request/Query';

import { Page } from 'Component/Header/Header.config';
import history from 'Util/History';
import { debounce } from 'Util/Request/Debounce';
import { isHomePageUrl } from 'Util/Url';

import { addHreflangTags } from '../../util/AddHreflangTags.js';
import HreflangUrlsQuery from '../query/HreflangUrls.query';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        seoConfigurations: state.SeoConfigurationReducer.seoConfigurations,
        configurations: state.ConfigReducer,
        urlRewrite: state.UrlRewritesReducer.urlRewrite,
    };
};

export class CmsPageContainerPlugin {
    constructExtended = (args, callback, instance) => {
        // eslint-disable-next-line no-param-reassign
        instance.state = {
            hreflangUrls: [],
        };
    };

    componentDidMount = (args, callback, _instance) => {
        const {
            updateBreadcrumbs,
            configurations: {
                code = '',
            } = {},
            cmsPage,
        } = _instance.props;
        const url_code = window.location.pathname.split('/')[1];
        const url_path = code === url_code ? window.location.pathname : `/${code}${window.location.pathname}`;
        callback(...args);
        updateBreadcrumbs({ ...cmsPage, url: url_path });
    };

    componentDidUpdate = (args, callback, _instance) => {
        const {
            pageIds,
            pageIdentifiers,
            seoConfigurations,
            urlRewrite: { type }
        } = _instance.props;

        const isActive = seoConfigurations?.hreflang?.isActive ?? false;
        const action = pageIdentifiers === 'home' ? 'cms_index_index' : 'cms';
        // eslint-disable-next-line no-unused-expressions
        if (type !== 'AMASTY_XLANDING_PAGE') {
            isActive && fetchQuery(HreflangUrlsQuery.getHreflangUrls(pageIds, action))
                .then(
                    (response) => {
                        addHreflangTags(response?.hreflangUrls || []);
                    }
                );
        }
        callback(...args);
    };

    onPageLoad = (args, callback, instance) => {
        const {
            updateMeta,
            setHeaderState,
            updateBreadcrumbs,
            configurations: {
                code = '',
            } = {},
        } = instance.props;
        const { cmsPage } = instance.props;
        const { location: { pathname } } = history;
        const {
            content_heading,
            meta_title,
            title,
            meta_description,
            meta_keywords,
        } = cmsPage;
        const url_code = window.location.pathname.split('/')[1];
        const url_path = code === url_code ? window.location.pathname : `/${code}${window.location.pathname}`;

        debounce(this.setOfflineNoticeSize, 300)();

        updateBreadcrumbs({ ...cmsPage, url: url_path });
        updateMeta({
            title: meta_title || title,
            description: meta_description,
            keywords: meta_keywords,
            canonical_url: window.location.href,
        });

        if (!isHomePageUrl(pathname)) {
            setHeaderState({
                name: Page.CMS_PAGE,
                title: content_heading,
                onBackClick: () => history.goBack(),
            });
        }
    };
}

const {
    componentDidUpdate,
    constructExtended,
    onPageLoad,
    componentDidMount,
} = new CmsPageContainerPlugin();

export default {
    'Route/CmsPage/Container': {
        'member-function': {
            componentDidUpdate,
            __construct: constructExtended,
            onPageLoad,
            componentDidMount,
        },
    },
    'Route/CmsPage/Container/mapStateToProps': {
        function: [
            {
                position: 50,
                implementation: mapStateToProps,
            },
        ],
    },
};
