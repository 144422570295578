/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from '@tilework/opus';

/** @namespace Efex/MorhaneFooter/Query/Config/Query */
export class ConfigQuery {
    getConfig() {
        const field = new Field('storeList')
            .addFieldList(this._getStoreListFields());

        return field;
    }

    _getStoreListFields() {
        return [
            new Field('name'),
            new Field('is_active'),
            new Field('base_url'),
            new Field('base_link_url'),
            new Field('code'),
        ];
    }
}

export default new ConfigQuery();
