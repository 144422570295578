import { connect } from 'react-redux';

import {
    CmsPageContainer as SourceCmsPageContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/CmsPage/CmsPage.container';

/** @namespace Celtik/Route/CmsPage/Container */
export class CmsPageContainer extends SourceCmsPageContainer {
    componentDidMount() {
        super.componentDidMount();
        const script = document.createElement('script');
        script.src = 'https://kit.fontawesome.com/fc9ceab567.js';
        script.async = true;
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        const script = document.querySelector('script[src="https://example.com/external-script.js"]');

        if (script) {
            document.body.removeChild(script);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
