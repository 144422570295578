/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/prop-types */
import { PureComponent } from 'react';

/** @namespace Efex/MorhaneSeo/Component/MetaRobots/Component */
export class MetaRobotsComponent extends PureComponent {
    render() {
        const {
            metaRobots: {
                robots = '',
            } = {},
        } = this.props;

        const robotsFormat = robots === 'INDEX,FOLLOW' ? 'INDEX, FOLLOW' : robots;

        return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
                { robotsFormat !== '' && <meta name="robots" content={ robotsFormat } /> }
            </>
        );
    }
}

export default MetaRobotsComponent;
