/* eslint-disable max-lines */
/* eslint-disable quote-props */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request/Query';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet';

import Footer from 'Component/Footer';
import CmsPage from 'Route/CmsPage';

import MetaRobots from '../component/MetaRobots';
import Opengraph from '../component/Opengraph';
import TwitterCard from '../component/TwitterCard';
import MicrodataQuery from '../query/Microdata.query';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        seoConfigurations: state.SeoConfigurationReducer.seoConfigurations,
        configurations: state.ConfigReducer,
        cmsPage: state.CmsReducer.cmsPage,
    };
};

export class HomePageContainerPlugin {
    constructExtended = (args, callback, instance) => {
        // eslint-disable-next-line no-param-reassign
        instance.state = {
            organizationMicrodata: {},
        };
    };

    fetchCustomVariables = (code, _instance) => {
        fetchQuery(MicrodataQuery.getOrganizationCustomVariables(code))
            .then(
                (response) => {
                    const { organizationMicrodata } = _instance.state;
                    const result = response?.customVariableConfig;
                    _instance.setState(
                        {
                            organizationMicrodata: {
                                ...organizationMicrodata,
                                ...result,
                            },
                        }
                    );
                }
            ).catch((e) => console.log(e));
    };

    fetchOrganizationConfigs = (code, _instance) => {
        fetchQuery(MicrodataQuery.getOrganizationConfiguration(code))
            .then(
                (response) => {
                    const { organizationMicrodata } = _instance.state;
                    const result = response?.organization;
                    _instance.setState(
                        {
                            organizationMicrodata: {
                                ...organizationMicrodata,
                                ...result,
                            },
                        }
                    );
                }
            ).catch((e) => console.log(e));
    };

    componentDidMount = async (args, callback, _instance) => {
        const {
            configurations: {
                code = '',
            } = {},
        } = _instance.props;

        this.fetchCustomVariables(code, _instance);
        this.fetchOrganizationConfigs(code, _instance);

        callback(...args);
    };

    componentDidUpdate = (args, callback, _instance) => {
        const { organizationMicrodata } = _instance.state;
        const {
            configurations: {
                code = '',
            } = {},
        } = _instance.props;

        if (Object.keys(organizationMicrodata).length === 0) {
            this.fetchCustomVariables(code, _instance);
            this.fetchOrganizationConfigs(code, _instance);
        }
        callback(...args);
    };

    renderOrganizationMicroData = (organizationMicrodata, props) => {
        const {
            addressCountry = '',
            addressLocality = '',
            addressRegion = '',
            areaServed = '',
            availableLanguage = '',
            duns = '',
            email = '',
            founding_date = '',
            global_location_number = '',
            iso_code = '',
            legal_name = '',
            max_number_employees = '',
            min_number_employees = '',
            name = '',
            phone = '',
            postalCode = '',
            review_website = '',
            social_links = '',
            streetAddress = '',
            vatID = '',
        } = organizationMicrodata;

        const {
            configurations: {
                base_url = '',
                base_link_url = '',
            } = {},
            cmsPage: {
                meta_description = '',
            } = {},
        } = props;

        const productRichSnippet = {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            'url': base_link_url,
            'logo': `${base_url}media/logo/default/Logo_3.png`,
            'image': `${base_url}media/logo/default/Logo_3.png`,
            'contactPoint': {
                '@type': 'ContactPoint',
                'contactType': 'customer service',
            },
            'address': {
                '@type': 'PostalAddress',
            },
            'areaServed': {
                '@type': 'Country',
            },
            'numberOfEmployees': {
                '@type': 'QuantitativeValue',
            },
            'aggregateRating': {
                '@type': 'AggregateRating',
                'bestRating': '5',
                'worstRating': '1',
                'ratingValue': '4',
                'reviewCount': '4010',
            },
        };

        if (name !== '') {
            productRichSnippet.name = name;
            productRichSnippet.alternateName = name;
        }

        if (legal_name !== '') {
            productRichSnippet.legalName = legal_name;
        }

        if (meta_description !== '') {
            productRichSnippet.description = meta_description;
        }

        if (email !== '') {
            productRichSnippet.email = email;
            productRichSnippet.contactPoint.email = email;
        }

        if (phone !== '') {
            productRichSnippet.telephone = phone;
            productRichSnippet.contactPoint.telephone = phone;
        }

        if (vatID !== '') {
            productRichSnippet.vatID = vatID;
            productRichSnippet.taxID = vatID;
        }

        if (iso_code !== '') {
            productRichSnippet.iso6523Code = iso_code;
        }

        if (duns !== '') {
            productRichSnippet.duns = duns;
        }

        if (global_location_number !== '') {
            productRichSnippet.globalLocationNumber = global_location_number;
        }

        if (founding_date !== '') {
            productRichSnippet.foundingDate = founding_date;
        }

        if (availableLanguage !== '') {
            productRichSnippet.contactPoint.availableLanguage = availableLanguage;
        }

        if (areaServed !== '') {
            const areaServedArr = areaServed.split(',');
            productRichSnippet.contactPoint.areaServed = areaServedArr;
            productRichSnippet.areaServed.name = areaServedArr;
        }

        if (streetAddress !== '') {
            productRichSnippet.address.streetAddress = streetAddress;
        }

        if (addressLocality !== '') {
            productRichSnippet.address.addressLocality = addressLocality;
        }

        if (postalCode !== '') {
            productRichSnippet.address.postalCode = postalCode;
        }

        if (addressRegion !== '') {
            productRichSnippet.address.addressRegion = addressRegion;
        }

        if (addressCountry !== '') {
            productRichSnippet.address.addressCountry = addressCountry;
        }

        if (min_number_employees !== '') {
            productRichSnippet.numberOfEmployees.minValue = min_number_employees;
        }

        if (max_number_employees !== '') {
            productRichSnippet.numberOfEmployees.maxValue = max_number_employees;
        }

        if (review_website !== '') {
            productRichSnippet.aggregateRating.url = review_website;
        }

        if (social_links !== '') {
            productRichSnippet.sameAs = social_links.split(',');
        }

        return (
            <script type="application/ld+json">
                { JSON.stringify(productRichSnippet) }
            </script>
        );
    };

    renderWebsiteMicroData = (props) => {
        const {
            configurations: {
                code = '',
            } = {},
        } = props;

        const url = `${window.location.origin}/${code}/search/{search_term_string}`;
        const androidUrl = `android-app://com.morhane/https/${window.location.host}/${code}/search/{search_term_string}`;
        const iosUrl = `ios-app://morhane.ios/https/${window.location.host}/${code}/search/{search_term_string}`;

        const websiteRichSnippet = {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            'url': `${window.location.origin}/${code}`,
            'potentialAction': [
                {
                    '@type': 'SearchAction',
                    'target': {
                        '@type': 'EntryPoint',
                        'urlTemplate': url,
                    },
                    'query-input': 'required name=search_term_string',
                },
                {
                    '@type': 'SearchAction',
                    'target': {
                        '@type': 'EntryPoint',
                        'urlTemplate': androidUrl,
                    },
                    'query-input': 'required name=search_term_string',
                },
                {
                    '@type': 'SearchAction',
                    'target': {
                        '@type': 'EntryPoint',
                        'urlTemplate': iosUrl,
                    },
                    'query-input': 'required name=search_term_string',
                },
            ],
        };

        return (
            <script type="application/ld+json">
                { JSON.stringify(websiteRichSnippet) }
            </script>
        );
    };

    renderOpengraph = (props) => (
        <Opengraph
          type="cms_index_index"
          entity={ props.cmsPage }
        />
    );

    renderTwitterCard = (props) => (
        <TwitterCard
          type="cms_index_index"
          entity={ props.cmsPage }
        />
    );

    renderMetaRobots = (props) => (
        <MetaRobots
          type="cms_index"
          entity={ props.cmsPage }
        />
    );

    render = (args, callback, instance) => {
        const { isInstallPromptAvailable, organizationMicrodata } = instance.state;

        return (
            <>
                <Helmet>
                    { this.renderOrganizationMicroData(organizationMicrodata, instance.props) }
                    { this.renderWebsiteMicroData(instance.props) }
                </Helmet>
                { createPortal({ ...this.renderMetaRobots(instance.props) }, document.head) }
                { createPortal({ ...this.renderOpengraph(instance.props) }, document.head) }
                { createPortal({ ...this.renderTwitterCard(instance.props) }, document.head) }
                <div block="HomePage" mods={ { mobile: !!isInstallPromptAvailable } }>
                    { instance.renderInstallPrompt }
                    <CmsPage { ...instance.containerProps() } />
                    <Footer isVisibleOnMobile />
                </div>
            </>
        );
    };
}

const {
    componentDidMount,
    componentDidUpdate,
    constructExtended,
    render,
} = new HomePageContainerPlugin();

export default {
    'Route/HomePage/Container': {
        'member-function': {
            componentDidMount,
            __construct: constructExtended,
            componentDidUpdate,
            render,
        },
    },
    'Route/HomePage/Container/mapStateToProps': {
        function: [
            {
                position: 50,
                implementation: mapStateToProps,
            },
        ],
    },
};
