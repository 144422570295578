/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { fireGenerateLead } from '../../event/lead';
import { waitForCallback } from '../../util/wait';

const AfterFormSubmit = async (args, callback, instance) => {
    await callback(...args);

    await waitForCallback(
        () => getStore().getState().NotificationReducer?.notifications
    );

    const notify = getStore().getState().NotificationReducer?.notifications;
    const result = notify[Object.keys(notify)];

    if (result.msgType === 'success') {
        const email = args[1];
        fireGenerateLead(email);
    }
};

export default {
    'Store/NewsletterSubscription/Dispatcher': {
        'member-function': {
            subscribeToNewsletter: AfterFormSubmit,
        },
    },
};
