import { Field } from 'Util/Query';

/** @namespace Efex/MorhaneSeo/Query/Microdata/Query */
export class MicrodataQuery {
    getOrganizationCustomVariables(store_code) {
        return new Field('customVariableConfig')
            .addArgument('store_code', 'String!', store_code)
            .addFieldList([
                'legal_name',
                'iso_code',
                'duns',
                'global_location_number',
                'founding_date',
                'min_number_employees',
                'max_number_employees',
                'social_links',
                'review_website',
                'fb_app_id',
                'twitter_username',
                'twitter_image',
            ]);
    }

    getOrganizationConfiguration(store_code) {
        return new Field('organization')
            .addArgument('store_code', 'String!', store_code)
            .addFieldList([
                'name',
                'email',
                'phone',
                'vatID',
                'areaServed',
                'availableLanguage',
                'streetAddress',
                'addressLocality',
                'postalCode',
                'addressRegion',
                'addressCountry',
            ]);
    }
}

export default new MicrodataQuery();
