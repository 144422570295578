/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { GTM_EVENT_KEY_SELECT_PROMOTION, GTM_EVENT_KEY_VIEW_PROMOTION } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/Promotion/fireViewPromotion */
export const fireViewPromotion = async (promotion_id, promotion_name, creative_name,) => {
    const creative_slot = promotion_name;

    pushToDataLayer({
        event: GTM_EVENT_KEY_VIEW_PROMOTION,
        ecommerce: {
            promotion_id,
            promotion_name,
            creative_name,
            creative_slot,
            items: [{
                item_id: promotion_id,
                item_name: promotion_name,
            }],
        },
    });
};

/** @namespace Scandiweb/Gtm/Event/General/fireSelectPromotion */
export const fireSelectPromotion = debounceCallback(
    async (promotion_id, promotion_name, creative_name,) => {
        const creative_slot = promotion_name;

        pushToDataLayer({
            event: GTM_EVENT_KEY_SELECT_PROMOTION,
            ecommerce: {
                promotion_id,
                promotion_name,
                creative_name,
                creative_slot,
                items: [{
                    item_id: promotion_id,
                    item_name: promotion_name,
                }],
            },
        });
    }
);
