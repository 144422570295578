/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */

import { createPortal } from 'react-dom';

import MetaRobots from '../component/MetaRobots';
import Opengraph from '../component/Opengraph';
import TwitterCard from '../component/TwitterCard';

export class CmsPageComponentPlugin {
    renderOpengraph = (props) => {
        const {
            cmsPage = {},
            pageIds = -1,
            pageIdentifiers = '',
        } = props;
        const entity = {
            ...cmsPage,
            id: pageIds,
        };

        return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              { pageIdentifiers !== 'home'
              && (
                  <Opengraph
                    type="cms"
                    entity={ entity }
                  />
              ) }
            </>
        );
    };

     renderTwitterCard = (props) => {
         const {
             cmsPage = {},
             pageIds = -1,
             pageIdentifiers = '',
         } = props;
         const entity = {
             ...cmsPage,
             id: pageIds,
         };

         return (
         // eslint-disable-next-line react/jsx-no-useless-fragment
             <>
              { pageIdentifiers !== 'home'
              && (
                  <TwitterCard
                    type="cms"
                    entity={ entity }
                  />
              ) }
             </>
         );
     };

    renderMetaRobots = (props) => {
        const {
            cmsPage = {},
            pageIds = -1,
            pageIdentifiers = '',
        } = props;
        const entity = {
            ...cmsPage,
            id: pageIds,
        };

        return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              { pageIdentifiers !== 'home'
              && (
                  <MetaRobots
                    type="cms"
                    entity={ entity }
                  />
              ) }
            </>
        );
    };

    render = (args, callback, instance) => (
        <>
              { createPortal({ ...this.renderMetaRobots(instance.props) }, document.head) }
              { createPortal({ ...this.renderOpengraph(instance.props) }, document.head) }
              { createPortal({ ...this.renderTwitterCard(instance.props) }, document.head) }
              { callback(...args) }
        </>
    );
}

const {
    render,
} = new CmsPageComponentPlugin();

export default {
    'Route/CmsPage/Container': {
        'member-function': {
            render,
        },
    },
};
