/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/prop-types */
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase';
import { PureComponent } from 'react';

/** @namespace Efex/MorhaneSeo/Component/Opengraph/Component */
export class OpengraphComponent extends PureComponent {
    getStarCounts(product) {
        const {
            review_count = 0,
            rating_summary = 0,
            best_rating,
            worst_rating,
        } = this.getReviewSummary(product);
        const STARS_GRANULARITY_PERCENT = 10;
        const ONE_STAR_SHARE = 20;
        const percentRounded = (rating_summary / STARS_GRANULARITY_PERCENT) * STARS_GRANULARITY_PERCENT;
        const full_count = (percentRounded / ONE_STAR_SHARE);

        return {
            review_count, full_count, best_rating, worst_rating,
        };
    }

    getReviewSummary(product = {}) {
        const {
            type_id: type,
            parent_id,
        } = product;

        if ((type === 'simple' || type === 'virtual') && parent_id !== null) {
            const { parentID, parentProduct: cachedParentProduct } = BrowserDatabase.getItem('PARENT_PRODUCT') || {};

            if (parent_id === parentID) {
                const {
                    variants = [],
                } = cachedParentProduct;

                const summary = [];
                const reviewsCount = variants.reduce((acc, variant) => {
                    if (!variant.rating_summary || Number(variant.rating_summary) === 0) {
                        return acc;
                    }

                    summary.push(Number(variant.rating_summary));

                    return {
                        review_count: acc.review_count + Number(variant.review_count),
                        rating_summary: (acc.rating_summary + Number(variant.rating_summary)),
                    };
                }, {
                    review_count: 0, rating_summary: 0,
                });

                const ratingSummary = summary.length ? reviewsCount.rating_summary / summary.length : 0;

                const ratings = variants.reduce((acc, variant) => {
                    if (!variant.rating_summary || Number(variant.rating_summary) === 0) {
                        return acc;
                    }

                    const share = 20;

                    if (variant.rating_summary / share > acc.best_rating) {
                        acc.best_rating = variant.rating_summary / share;
                    }

                    if (variant.rating_summary / share < acc.worst_rating) {
                        acc.worst_rating = variant.rating_summary / share;
                    }

                    return acc;
                }, {
                    best_rating: -1, worst_rating: 6,
                });

                return {
                    review_count: reviewsCount.review_count,
                    rating_summary: ratingSummary,
                    best_rating: ratings.best_rating,
                    worst_rating: ratings.worst_rating,
                };
            }
        }

        return false;
    }

    getGeneralFields(type) {
        const {
            configurations: {
                code = '',
            } = {},
            customVariables: {
                fb_app_id = '',
            } = {},
            hreflangLocales = [],
        } = this.props;

        return (
            <>
                <meta property="og:site_name " content="morhane" />
                <meta property="og:type" content={ type } />
                { hreflangLocales.length !== 0 && hreflangLocales.map((hreflangLocale) => {
                    const url_code = hreflangLocale.url.split('/')[3];

                    return (
                        <>
                            { url_code === code
                                && <meta property="og:locale" content={ hreflangLocale.locale } /> }
                            { url_code !== code
                                && <meta property="og:locale:alternate" content={ hreflangLocale.locale } /> }
                        </>
                    );
                }) }
                { fb_app_id !== '' && <meta property="fb:app_id" content={ fb_app_id } /> }
            </>
        );
    }

    renderProduct() {
        const {
            entity,
            entity: {
                name = '',
                image: {
                    url: imageUrl = '',
                } = {},
                url: productUrl = '',
                price_range: {
                    maximum_price: {
                        default_final_price: {
                            value = '',
                            currency = '',
                        } = {},
                    } = {},
                } = {},
                stock_status = '',
                attributes: {
                    manufacturer: {
                        attribute_options = {},
                    } = {},
                } = {},
                meta_description = '',
            } = {},
            meta: {
                title = '',
            } = {},
        } = this.props;

        const ogTitle = name === '' ? title : name;
        const url = `${window.location.origin}${productUrl}/?source=facebook`;
        const isBrandExisted = Object.keys(attribute_options).length !== 0
            && Object.values(attribute_options)[0]?.label;
        const brand = Object.values(attribute_options)[0]?.label;
        const {
            full_count,
        } = this.getStarCounts(entity);
        let availability = '';

        if (stock_status === 'IN_STOCK') {
            availability = 'in stock';
        } else if (stock_status === 'OUT_OF_STOCK') {
            availability = 'out of stock';
        }

        return (
            <>
                { this.getGeneralFields('product') }
                <meta property="og:title" content={ ogTitle } />
                <meta property="og:description" content={ meta_description } />
                <meta property="og:url" content={ url } />
                <meta property="og:image" content={ imageUrl } />
                <meta property="product:price:amount" content={ value } />
                <meta property="product:price:currency" content={ currency } />
                { isBrandExisted && <meta property="product:brand" content={ brand } /> }
                <meta property="product:product_link" content={ url } />
                { availability !== '' && <meta property="product:availability" content={ availability } /> }
                { availability !== '' && <meta property="og:availability" content={ availability } /> }
                { full_count === 0 && <meta property="og:rating" content={ full_count } /> }
                { full_count !== 0 && <meta property="og:rating" content={ full_count.toFixed(2) } /> }
            </>
        );
    }

    renderCategory() {
        const {
            entity: {
                name = '',
                image = '',
                url: categoryUrl = '',
                meta_description = '',
            } = {},
            meta: {
                title = '',
            } = {},
        } = this.props;

        const ogTitle = name === '' ? title : name;
        const url = `${window.location.origin}${categoryUrl}/?source=facebook`;

        return (
            <>
                { this.getGeneralFields('website') }
                <meta property="og:title" content={ ogTitle } />
                <meta property="og:description" content={ meta_description } />
                <meta property="og:url" content={ url } />
                <meta property="og:image" content={ image } />
            </>
        );
    }

    renderHome() {
        const {
            entity: {
                meta_description = '',
                meta_title = '',
            } = {},
            configurations: {
                base_url = '',
                header_logo_src = '',
            } = {},
        } = this.props;

        const logo = `${base_url}media/logo/${header_logo_src}`;
        const url = `${window.location.origin}/?source=facebook`;

        return (
            <>
                { this.getGeneralFields('website') }
                <meta property="og:title" content={ meta_title } />
                <meta property="og:description" content={ meta_description } />
                <meta property="og:url" content={ url } />
                <meta property="og:image" content={ logo } />
            </>
        );
    }

    renderCms() {
        const {
            entity: {
                meta_description = '',
                meta_title = '',
            } = {},
            configurations: {
                base_url = '',
                header_logo_src = '',
            } = {},
        } = this.props;

        const logo = `${base_url}media/logo/${header_logo_src}`;
        const url = `${window.location.href}/?source=facebook`;

        return (
            <>
                { this.getGeneralFields('website') }
                <meta property="og:title" content={ meta_title } />
                <meta property="og:description" content={ meta_description } />
                <meta property="og:url" content={ url } />
                <meta property="og:image" content={ logo } />
            </>
        );
    }

    renderEntity() {
        const { type = '' } = this.props;
        switch (type) {
        case 'product':
            return this.renderProduct();
        case 'category':
            return this.renderCategory();
        case 'cms_index_index':
            return this.renderHome();
        case 'cms':
            return this.renderCms();
        default:
            return this.renderProduct();
        }
    }

    render() {
        return this.renderEntity();
    }
}

export default OpengraphComponent;
