import {
    faSquareFacebook, faSquareInstagram, faSquarePinterest, faSquareXTwitter, faSquareYoutube, faTiktok, faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {
    faChevronRight, faCircleCheck, faComments, faEnvelope, faPhoneAlt, faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '@scandipwa/scandipwa/src/component/Link';
import NewsletterSubscription from '@scandipwa/scandipwa/src/component/NewsletterSubscription';
import RenderWhenVisible from '@scandipwa/scandipwa/src/component/RenderWhenVisible';

import ContentWrapper from 'Component/ContentWrapper';
import { FieldType } from 'Component/Field/Field.config';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';
import getStore from 'Util/Store';

import android from '../icons/android.png';
import AR from '../icons/arrow.png';
import AT from '../icons/AT.png';
import BE from '../icons/BE.png';
import DE from '../icons/DE.png';
import ES from '../icons/ES.png';
import FR from '../icons/FR.png';
import ios from '../icons/ios.png';
import IT from '../icons/IT.png';
import NL from '../icons/NL.png';
import Support from '../icons/support-staff.jpg';
import TR from '../icons/TR.png';
import ConfigQuery from '../query/Config.query';

import '../style/Footer.style';

const query = ConfigQuery.getConfig();
const queryx = executeGet(prepareQuery(query));
const queryxx = Promise.resolve(queryx);
let result;

Promise.all([queryxx]).then((values) => {
    result = values;
});

const renderActions = (args, callback, instance) => (
        <button
          type={ FieldType.SUBMIT }
          block="Button"
          mods={ { isHollow: true } }
          aria-label={ __('Submit') }
        >
            { __('sign up') }
{ ' ' }
<img src={ AR } />
        </button>
);

const isAppleDevice = () => /iPhone|iPad|iPod|Mac/.test(navigator.platform);

const renderCopyrightContent = (args, callback, instance) => (
        <ContentWrapper
          mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
          wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
          label=""
        >
            <div className="Footer-Countries">
                <div className="Footer-Country">
                    <a href="https://morhane.nl/nl/" className="Footer-Country-Anchor" hrefLang="nl-NL">
                        <img src={ NL } />
                        <span>morhane.nl</span>
                    </a>
                </div>

                <div className="Footer-Country">
                    <a href="https://morhane.be/nl_be/" className="Footer-Country-Anchor" hrefLang="nl-BE">
                        <img src={ BE } />
                        <span>morhane.be</span>
                    </a>
                </div>

                <div className="Footer-Country">
                    <a href="https://morhane.de/de/" className="Footer-Country-Anchor" hrefLang="de-DE">
                        <img src={ DE } />
                        <span>morhane.de</span>
                    </a>
                </div>

                <div className="Footer-Country">
                    <a href="https://morhane.fr/fr/" className="Footer-Country-Anchor" hrefLang="fr-FR">
                        <img src={ FR } />
                        <span>morhane.fr</span>
                    </a>
                </div>

                <div className="Footer-Country">
                    <Link to="https://morhane.es/es/" className="Footer-Country-Anchor" hrefLang="es-ES">
                        <img src={ ES } />
                        <span>morhane.es</span>
                    </Link>
                </div>

                <div className="Footer-Country">
                    <a href="https://morhane.it/it/" className="Footer-Country-Anchor" hrefLang="it-IT">
                        <img src={ IT } />
                        <span>morhane.it</span>
                    </a>
                </div>

                <div className="Footer-Country">
                    <a href="https://morhane.at/de_at/" className="Footer-Country-Anchor" hrefLang="de-AT">
                        <img src={ AT } />
                        <span>morhane.at</span>
                    </a>
                </div>

                <div className="Footer-Country">
                    <a href="https://morhane.com/tr/" className="Footer-Country-Anchor" hrefLang="tr">
                        <img src={ TR } />
                        <span>morhane.com</span>
                    </a>
                </div>
            </div>
        </ContentWrapper>
);

const render = (args, callback, instance) => {
    const { isVisibleOnMobile, device } = instance.props;

    if (!isVisibleOnMobile && device.isMobile) {
        return null;
    }

    if (isVisibleOnMobile && !device.isMobile) {
        return null;
    }

    const openTimeText = getStore().getState().ConfigReducer.open_time;
    const closingTimeText = getStore().getState().ConfigReducer.closing_time;
    const { timezone } = getStore().getState().ConfigReducer;
    const storeHours = getStore().getState().ConfigReducer.store_hours;
    const timeOptions = {
        timeZone: timezone,
        dateStyle: 'short',
        timeStyle: 'short',
    };

    const time = new Intl.DateTimeFormat('en-US', timeOptions).format(new Date());
    const storeTime = new Date(time);
    const times = storeHours?.split('-') || ['10', '17'];
    times.forEach((time) => time.trim());
    const openingTime = parseInt(times[0], 10);
    const closingTime = parseInt(times[1], 10);
    const isClosed = storeTime.getHours() < openingTime || storeTime.getHours() >= closingTime;

    return (
        <RenderWhenVisible>
            <div className="FooterAction">
                <div className="FooterAction-Contact">
                    <div className="FooterAction-Contact-Title">
                        <div className="FooterAction-Contact-Title-TitleOne">
                            <img className="FooterAction-Contact-Title-Image" src={ Support } />
                            <div className={ `FooterAction-Contact-Title-Indicator ${isClosed ? 'Off' : ''}` } />
                        </div>
                        <div className="FooterAction-Contact-Title-Content">
                            <div className="FooterAction-Contact-Title-P1">
                                { __('Need assistance?') }
                            </div>
                            <div className="FooterAction-Contact-Title-P2">
                                <span>
                                    { isClosed ? closingTimeText : openTimeText }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="FooterAction-Contact-Options FooterAction-Contact-Help">
                            <a href="https://m.me/111059444407014" target="_blank" rel="noreferrer">
                                <div className="FooterAction-Contact-OptionsComments">
                                <FontAwesomeIcon icon={ faComments } size="2xl" style={ { color: '#9046ff' } } />
                                    <div className="FooterAction-Contact-Info">
                                        <span className="FooterAction-Contact-Info-p1">
                                            { __('Live chat') }
                                        </span>
                                        <span className="FooterAction-Contact-Info-p2">
                                            { __('Answer within 3 min.') }
                                        </span>
                                    </div>
                                    <div className="FooterAction-Contact-lastItem">
                                        <FontAwesomeIcon icon={ faChevronRight } size="lg" style={ { color: '#989898' } } />
                                    </div>
                                </div>
                            </a>
                            <Link to="https://wa.me/message/HTAOHLBCTGK3N1" target="_blank" rel="noopener noreferrer">
                                <div className="FooterAction-Contact-OptionsComments">
                                    <FontAwesomeIcon icon={ faWhatsapp } size="2xl" style={ { color: '#9046ff' } } />
                                    <div className="FooterAction-Contact-Info">
                                        <span className="FooterAction-Contact-Info-p1">
                                            { __('Whatsapp') }
                                            { ' ' }
                                            085 760 3479
                                        </span>
                                        <span className="FooterAction-Contact-Info-p2">
                                            { __('Answer within 3 min.') }
                                        </span>
                                    </div>
                                    <div className="FooterAction-Contact-lastItem">
                                        <FontAwesomeIcon icon={ faChevronRight } size="lg" style={ { color: '#989898' } } />
                                    </div>
                                </div>
                            </Link>
                            <a href="tel:+31857603479">
                                <div className="FooterAction-Contact-OptionsComments">
                                    <FontAwesomeIcon icon={ faPhoneAlt } size="2xl" style={ { color: '#9046ff' } } />
                                    <div className="FooterAction-Contact-Info">
                                        <span className="FooterAction-Contact-Info-p1">
                                            { __('Call') }
                                            { ' ' }
                                            085 760 3479
                                        </span>
                                        <span className="FooterAction-Contact-Info-p2">
                                            { __('Very busy at the moment') }
                                        </span>
                                    </div>
                                    <div className="FooterAction-Contact-lastItem">
                                        <FontAwesomeIcon icon={ faChevronRight } size="lg" style={ { color: '#989898' } } />
                                    </div>
                                </div>
                            </a>
                            <a href="/contact">
                                <div className="FooterAction-Contact-OptionsComments">
                                    <FontAwesomeIcon icon={ faEnvelope } size="2xl" style={ { color: '#9046ff' } } />
                                    <div className="FooterAction-Contact-Info">
                                        <span className="FooterAction-Contact-Info-p1">{ __('Contactform') }</span>
                                        <span className="FooterAction-Contact-Info-p2">

                                            { __('Answer within 1 business day') }
                                        </span>
                                    </div>
                                    <div className="FooterAction-Contact-lastItem">
                                        <FontAwesomeIcon icon={ faChevronRight } size="lg" style={ { color: '#989898' } } />
                                    </div>
                                </div>
                            </a>
                            <a href="/contact">
                                <div className="FooterAction-Contact-OptionsComments FooterAction-Contact-Faq">
                                <FontAwesomeIcon icon={ faQuestionCircle } size="2xl" style={ { color: '#9046ff' } } />
                                    <div className="FooterAction-Contact-Info">
                                        <span className="FooterAction-Contact-Info-p1">{ __('FAQ') }</span>
                                        <span className="FooterAction-Contact-Info-p2">
                                            { __('Find the answer to your questions') }
                                        </span>
                                    </div>
                                    <div className="FooterAction-Contact-lastItem">
                                        <FontAwesomeIcon icon={ faChevronRight } size="lg" style={ { color: '#989898' } } />
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="FooterAction-Contact">
                    <div className="FooterAction-Contact-Title">
                        <div className="FooterAction-Contact-Title-Content">
                            <div className="FooterAction-Contact-Title-P1">
                                { __("Don't miss out on our offers?") }
                            </div>
                            <div className="FooterAction-Contact-Title-P2">
                                { __('Stay tuned and never miss out on discounts and promotions!') }
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="FooterAction-Contact-Options FooterAction-Contact-Offer">
                            <div className="FooterAction-Contact-Playstore">
                                <span className="FooterAction-Contact-Playstoreinfo">
                                    { __('Download our app') }
                                </span>
                                <div className="FooterAction-Contact-Store">
                                    <Link to="https://apps.apple.com/us/app/morhane/id1575110448" target="_blank" rel="noopener noreferrer">
                                        <img className="FooterAction-Contact-Image FooterAction-Contact-App" src={ ios } />
                                    </Link>
                                { !isAppleDevice() && (
                                    <Link to="https://play.google.com/store/apps/details?id=morhane.android" target="_blank" rel="noopener noreferrer">
                                        <img className="FooterAction-Contact-Image FooterAction-Contact-App" src={ android } />
                                    </Link>
                                ) }
                                </div>
                            </div>
                            <div className="FooterAction-Contact-Playstore">
                                <span className="FooterAction-Contact-Playstoreinfo">
                                    { __('Join our newsletter') }
                                </span>
                                <NewsletterSubscription key="NewsletterSubscription" />
                            </div>
                        </div>
                    </div>

                    <div className="FooterAction-Contact-Playstore1">
                        <span>
                            { __('Follow us') }
                            { ' ' }
                        </span>
                        <div className="FooterAction-Contact-Followinfo">
                            <a href="https://www.facebook.com/morhane.nl/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={ faSquareFacebook } size="2xl" style={ { color: '#000000' } } />
                            </a>
                            <a href="https://www.instagram.com/morhane.nl/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={ faSquareInstagram } size="2xl" style={ { color: '#000000' } } />
                            </a>
                            <a href="https://www.youtube.com/@morhane_nl/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={ faSquareYoutube } size="2xl" style={ { color: '#000000' } } />
                            </a>
                            <a href="https://www.pinterest.com/morhane_nl/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={ faSquarePinterest } size="2xl" style={ { color: '#000000' } } />
                            </a>
                            <a href="https://x.com/morhane_nl/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={ faSquareXTwitter } size="2xl" style={ { color: '#000000' } } />
                            </a>
                            <a href="https://www.tiktok.com/@morhane.nl/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={ faTiktok } size="2xl" style={ { color: '#000000' } } />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="USP">
                <div className="USP-Ul">
                    <div className="USP-Item">
                    <FontAwesomeIcon icon={ faCircleCheck } size="lg" />
                        <span>{ __('home-usp-1') }</span>
                    </div>

                    <div className="USP-Item">
                    <FontAwesomeIcon icon={ faCircleCheck } size="lg" />
                        <span>{ __('home-usp-2') }</span>
                    </div>

                    <div className="USP-Item">
                    <FontAwesomeIcon icon={ faCircleCheck } size="lg" />
                        <span>{ __('home-usp-3') }</span>
                    </div>

                    <div className="USP-Item">
                    <FontAwesomeIcon icon={ faCircleCheck } size="lg" />
                        <span>{ __('home-usp-4') }</span>
                    </div>

                    <div className="USP-Item">
                    <FontAwesomeIcon icon={ faCircleCheck } size="lg" />
                        <span>{ __('home-usp-1') }</span>
                    </div>
                </div>
            </div>
            <footer block="Footer" aria-label="Footer">
                { instance.renderContent() }
                { instance.renderCopyrightContent() }
            </footer>
        </RenderWhenVisible>
    );
};

const renderColumn = (args, callback, instance) => {
    const {
        mods = {},
    } = args[0];

    if (mods?.isNewsletter) {
        return null;
    }

    return callback.apply(instance, args);
};

export const containerProps = (args, callback, instance) => {
    const { isVisibleOnMobile, device } = instance.props;

    if (device.isMobile) {
        return {
            ...callback(...args),
            isVisibleOnMobile: !isVisibleOnMobile,
        };
    }

    return {
        ...callback(...args),
    };
};

export default {
    'Component/Footer/Component': {
        'member-function': {
            renderCopyrightContent,
            renderColumn,
            render,
        },
    },
    'Component/Footer/Container': {
        'member-function': {
            containerProps,
        },
    },
    'Component/NewsletterSubscription/Component': {
        'member-function': {
            renderActions,
        },
    },
};
