/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { sha256 } from 'js-sha256';

import { GTM_EVENT_KEY_GENERATE_LEAD } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/General/fireGenerateLead */
export const fireGenerateLead = debounceCallback(
    async (email) => {
        pushToDataLayer({
            event: GTM_EVENT_KEY_GENERATE_LEAD,
            lead_email: sha256(email),
        });
    }
);
