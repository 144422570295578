import { Field } from 'Util/Query';

/** @namespace Efex/MorhaneSeo/Query/MetaRobots/Query */
export class MetaRobotsQuery {
    getMetaRobots(id, action) {
        return new Field('metaRobots')
            .addArgument('id', 'Int!', id)
            .addArgument('action', 'String!', action)
            .addFieldList(['robots']);
    }
}

export default new MetaRobotsQuery();
