/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request/Query';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import MicrodataQuery from '../../query/Microdata.query';
import TwitterCardComponent from './TwitterCard.component';

/** @namespace Efex/MorhaneSeo/Component/TwitterCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    configurations: state.ConfigReducer,
    meta: state.MetaReducer,
    seoConfigurations: state.SeoConfigurationReducer.seoConfigurations,
});

/** @namespace Efex/MorhaneSeo/Component/TwitterCard/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Efex/MorhaneSeo/Component/TwitterCard/Container */
export class TwitterCardContainer extends PureComponent {
    static defaultProps = {
        configurations: {},
    };

    __construct() {
        this.state = {
            customVariables: [],
        };
    }

    componentDidMount() {
        const {
            configurations: {
                code,
            } = {},
        } = this.props;

        this.fetchCustomVariables(code);
    }

    fetchCustomVariables(code) {
        fetchQuery(MicrodataQuery.getOrganizationCustomVariables(code))
            .then(
                /** @namespace Efex/MorhaneSeo/Component/TwitterCard/Container/TwitterCardContainer/fetchCustomVariables/then/catch/fetchQuery/then */
                (response) => {
                    const { customVariables } = this.state;
                    const result = response?.customVariableConfig;
                    this.setState(
                        {
                            customVariables: {
                                ...customVariables,
                                ...result,
                            },
                        }
                    );
                }
            ).catch(
                /** @namespace Efex/MorhaneSeo/Component/TwitterCard/Container/TwitterCardContainer/fetchCustomVariables/then/catch */
                (e) => console.log(e)
            );
    }

    containerProps() {
        const {
            configurations,
            meta,
            type,
            entity,
            seoConfigurations,
        } = this.props;

        const { customVariables } = this.state;

        return {
            configurations,
            meta,
            type,
            entity,
            seoConfigurations,
            customVariables,
        };
    }

    render() {
        return (
            <TwitterCardComponent
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TwitterCardContainer);
